.content {
  max-width: 623px;
  width: 100%;
  margin: 0 auto;

  &Allocated {
    margin-bottom: 24px;

    &Wrap {
      width: 100%;
      height: 10px;
      background-color: var(--color-gray-900);
      border-radius: 20px;
      overflow: hidden;
    }

    &Progress {
      height: 100%;
      background-color: var(--color-primary-500);
    }

    &Inputs {
      display: grid;
      align-items: flex-end;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 24px;

      >div {
        align-self: flex-start;
      }
    }
  }
}

.checkbox18Content {
  display: flex;
  align-items: center;
}

.helpIcon18Tooltip {
  margin-left: 4px;
  height: 16px;
  width: 16px;
}

.helpIcon18 {
  height: 16px;
  width: 16px;
  color: var(--color-gray-400);
  cursor: pointer;

  &:hover {
    color: var(--color-gray-300);
  }
}

.attendeesList {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .attendeesItem {
    display: flex;
    align-items: center;

    .attendeesItemUser {
      flex: 1;
    }

    .resendAllocation {
      margin-right: 16px;
    }

    .ticket {
      display: flex;
      align-items: center;
    }
  }
}