.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;

  @media (max-width: 800px) {
    overflow: hidden;
  }
}
