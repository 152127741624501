.drawerContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    margin-bottom: 44px;
  }

  .content {
    flex: 1;

    .tipAmountsWrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .tipAmount {
        border: 1px solid var(--color-gray-700);
        flex: 1;
        padding: 16px;
        color: var(--color-gray-200);
        font-family: var(--font-family-poppins);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          border: 1px solid var(--color-gray-600);
          color: var(--color-white);
        }

        &.selected {
          border: 1px solid var(--color-primary-500);
          color: var(--color-white);
        }

        &.disabled {
          border: 1px solid var(--color-gray-900);
          color: var(--color-gray-800);
        }
      }
    }
  }

  .summary {
    margin-top: auto;
    border-top: 1px solid var(--color-gray-800);

    .totalWrapper {
      padding: 32px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .totalLabel {}

      .totalAmount {}
    }
  }
}

.paymentButtons {
  margin-top: auto;

  button {
    flex: 1;
  }
}

.artistImg {
  border-radius: 50%;
}