.countdown {
  position: absolute;
  z-index: 10;
  bottom: -55.5px;
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0px -100vw -100vw -100vw);
  -webkit-clip-path: none;
  &::after {
    content: "";
    background: #ebff221a;
    box-shadow: 0px 9.8px 107.8px 0px #ebff2280;
    border: 1.46px solid #ebff221a;
    width: 256px;
    height: 82px;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 70px;
    z-index: -1;
  }
  &Row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
      radial-gradient(50% 126.49% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    backdrop-filter: blur(40px);
    border: 1px solid #ffffff33;
    padding: 14px 16px;
    border-radius: 20px;
  }
  &Item {
    text-align: center;
    position: relative;
    padding: 0 42px;
    &::after {
      content: "";
      width: 1px;
      background: #ffffff66;
      backdrop-filter: blur(40px);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 48px;
    }
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
      &::after {
        display: none;
      }
    }
  }
}
