.footer {
  background-color: var(--color-background);
  color: var(--color-gray-25);
  padding-top: 80px;
  font-family: "Poppins", sans-serif;
  &Top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &Icons {
    display: flex;
    gap: 12px;
  }
  &Locations {
    &Title {
      margin-bottom: 10px;
    }
    &List {
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &Copyright {
    padding: 24px 0;
    border-top: 1px solid var(--color-gray-900);
    font-size: 14px;
    color: var(--color-gray-400);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 772px) {
  .footer {
    padding-top: 40px;
    &Copyright {
      padding: 16px 0;
      flex-direction: column-reverse;
      gap: 8px;
    }
  }
}
