.divider {
  display: flex;
  align-items: center;
  gap: 16px;

  .line {
    flex: 1;
    height: 1px;
    background-color: var(--color-gray-800);
  }

  .text {
    font-size: 14px;
    white-space: nowrap;
    color: var(--color-gray-600);
  }
}