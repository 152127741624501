.privacy {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;

  &Date{
    line-height: 26px;
    font-size: 16px;
    color: #B9BBC9;
    text-align: center;
    font-weight: 400;
    padding-bottom: 96px;
  }

  &Body{
    padding-bottom: 48px;
  }

  &Title {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 24px;
    font-weight: 600!important;
  }

  &ListTitle {
    padding: 0 0 16px 0;
  }

  .list{
    margin-left: 25px;
    li{
      list-style: disc;
    }
  }
}

.mailto {
  color: #fff;
  text-decoration: underline;
}
