.inputFieldWrapper {
  font-family: var(--font-family-poppins);

  .label {
    display: block;
  }

  .inputGroup {
    border-radius: 4px;
    background: transparent;
    border: 1px solid var(--color-gray-700);
    display: flex;
    align-items: center;
    overflow: hidden;

    .addonBefore,
    .addonAfter {
      padding: 8px 10px;
      height: 100%;
      background: transparent;
      color: var(--color-white);
      font-size: 16px;
    }

    .addonBefore {
      border-right: 1px solid var(--color-gray-700);
    }

    .addonAfter {
      border-left: 1px solid var(--color-gray-700);
    }

    &.invalid {
      border: 1px solid var(--color-error);

      .addonAfter,
      .addonBefore {
        border-color: var(--color-error);
      }
    }

    &:focus-within:not(.invalid) {
      border-color: var(--color-primary-500);

      .addonAfter,
      .addonBefore {
        border-color: var(--color-primary-500);
      }
    }

    //TODO
    &:hover:not(:focus-within):not(.invalid) {
      border-color: var(--color-gray-600);

      .addonAfter,
      .addonBefore {
        border-color: var(--color-gray-600);
      }
    }

    &.disabled {
      cursor: not-allowed;

      .addonAfter,
      .addonBefore,
      .prefix,
      .suffix {
        color: var(--color-gray-600);
      }

      background: var(--color-gray-850);
    }

    .inputContainer {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .prefix,
      .suffix {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        font-size: 16px;
        line-height: 1;
        flex-shrink: 0;
      }

      .prefix {
        padding-right: 0px;
        padding-left: 10px;
        margin-right: -6px;
      }

      .suffix {
        padding-right: 10px;
        padding-left: 0px;
        margin-left: -6px;

        .invalidCircleIcon {
          width: 16px;
          height: 16px;
          color: var(--color-error);
        }
      }

      .input {
        flex: 1;
        width: 100%;
        padding: 8px 12px;
        font-size: 16px;
        line-height: 24px;
        height: 100%;
        font-family: var(--font-family-poppins);
        color: var(--color-white);
        background: transparent;
        border: none;
        outline: none;

        &::placeholder {
          color: var(--color-gray-700);
        }

        &:disabled {
          color: var(--color-gray-600);
          cursor: not-allowed;
        }
      }
    }
  }

  .inputOtpWrapper {
    .otpSeparator {
      width: 12px;
    }

    .input {
      padding: 8px 0;
      font-size: 24px;
      line-height: 32px;
      font-family: var(--font-family-poppins);
      font-weight: 600;
      width: 48px !important;
      height: 48px !important;
      color: var(--color-white);
      background: transparent;
      border: 1px solid var(--color-gray-600);
      border-radius: 8px;
      outline: none;

      &:focus {
        border-color: var(--color-primary-500);
      }

      &::placeholder {
        color: var(--color-gray-700);
      }

      &:disabled {
        color: var(--color-gray-600);
        background: var(--color-gray-850);
        cursor: not-allowed;
      }

      &.invalid {
        border: 1px solid var(--color-error);
      }

      &.success {
        border: 1px solid var(--color-success);
        cursor: not-allowed;
      }

    }
  }

  .hint {
    margin-top: 6px;
  }

  .errorText {
    margin-top: 6px;
    color: var(--color-error);
  }
}