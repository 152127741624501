.meetEntx {
  padding: 128px 0;
  &Row {
    display: flex;
    justify-content: space-between;
    gap: 166px;
  }
  &ListTitle{
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  &ListLeft{
    flex: 5;
  }
  &ListRight{
    flex: 7;
  }
  &List {
    display: flex;
    flex-direction: column;
    gap: 64px;
    flex: 0 0 700px;
    max-width: 700px;
    counter-reset: counter;
    li {
      display: flex;
      align-items: center;
      gap: 48px;
      &::before {
        content: counters(counter, ".", decimal-leading-zero);
        counter-increment: counter;
        display: block;
        font-family: var(--font-family-spacemono);
        color: var(--color-gray-900);
        font-size: 100px;
        line-height: 100px;
      }
    }
  }
}

@media (max-width: 772px) {
  .meetEntx {
    padding: 64px 0;
    &Row {
      flex-direction: column;
      gap: 24px;
    }
    &List {
      gap: 40px;
      li {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        &::before {
          font-size: 70px;
          line-height: 70px;
        }
      }
    }
  }
}
