.wrapper {}

.liveLabelWrapper {
  padding: 4px 8px;
  border: 1px solid rgba(254, 37, 14, 0.70);
  border-radius: 40px;
  background: #FE250E;
  backdrop-filter: blur(26.666667938232422px);
  color: #FFF;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
}

.infoElementWrapper {
  .watchBlock {
    display: flex;
    align-items: center;

    .showIcon {
      margin-right: 8px;
      color: var(--color-gray-100);
    }
  }
}