.sumsubBlock {
  width: 100%;
  max-width: 632px;
  margin: 0 auto;
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigateButton {
  margin-top: 12px;
  text-align: center;
}