.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-width: 100%;
  width: 600px;
  background-color: var(--color-background);
  border-radius: 12px 12px 0 0;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &CloseIcon {
    cursor: pointer;
  }
}

.wrapper {
  border-radius: 12px;
  background-color: var(--color-gray-900);
  margin-bottom: 8px;

  &Info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 6px 10px;
    background-color: var(--color-gray-800);
    border-radius: 12px;

    &Warning {
      background-color: var(--color-primary-850);
      border-radius: 12px 12px 0 0;
    }

    &Danger {
      background-color: var(--color-error);
    }
  }

  &Content {
    padding: 12px;
  }

  &EntryLog {
    display: flex;
    justify-content: space-between;
  }
}
