.breadcrumbs {
  padding: 32px 0;
  position: relative;
  z-index: 10;
  &Link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}
