@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "./_reset.scss";
@import "./variables.scss";

body {
  margin: 0;
  font-family: var(--font-family-kanit);
  font-size: 16px;
  background-color: var(--color-background);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

button {
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-right: 64px;
  padding-left: 64px;
}

@media (max-width: 772px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}

/* Margin auto shortcuts */
.m-t-auto {
  margin-top: auto;
}

.m-b-auto {
  margin-bottom: auto;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

/* TEXT-ALIGN shortcuts */
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* FONT-WEIGHT shortcuts */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

//border-width
.bw-1 {
  border-width: 1px !important;
}

.bw-2 {
  border-width: 2px !important;
}

.bw-3 {
  border-width: 3px !important;
}

.bw-3 {
  border-width: 4px !important;
}

/* Generate margins and paddings */
$spaceamounts: (
  -64,
  -60,
  -56,
  -52,
  -48,
  -44,
  -40,
  -36,
  32,
  -28,
  -24,
  -20,
  -16,
  -12,
  -8,
  -4,
  0,
  2,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  24,
  28,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  60,
  64
);
$sides: (
  all,
  x,
  y,
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side =="all" {
      .m-#{$space} {
        margin: #{$space}px #{$space}px !important;
      }

      .p-#{$space} {
        padding: #{$space}px #{$space}px !important;
      }

      .gap-#{$space} {
        gap: #{$space}px !important;
      }

      .gap-col-#{$space} {
        gap: #{$space}px !important;
      }

      @each $breakpoint, $breakpoint-value in $breakpoints {
        @if $breakpoint-value !=0 {
          @media (max-width: $breakpoint-value) {

            div.#{$breakpoint}-m-#{$space},
            p.#{$breakpoint}-m-#{$space},
            button.#{$breakpoint}-m-#{$space},
            span.#{$breakpoint}-m-#{$space} {
              margin: #{$space}px #{$space}px !important;
            }

            div.#{$breakpoint}-p-#{$space},
            p.#{$breakpoint}-p-#{$space},
            button.#{$breakpoint}-p-#{$space},
            span.#{$breakpoint}-p-#{$space} {
              padding: #{$space}px #{$space}px !important;
            }

            div.#{$breakpoint}-gap-#{$space},
            p.#{$breakpoint}-gap-#{$space},
            button.#{$breakpoint}-gap-#{$space},
            span.#{$breakpoint}-gap-#{$space} {
              gap: #{$space}px !important;
            }

            div.#{$breakpoint}-gap-col-#{$space},
            p.#{$breakpoint}-gap-col-#{$space},
            button.#{$breakpoint}-gap-col-#{$space},
            span.#{$breakpoint}-gap-col-#{$space} {
              gap: #{$space}px !important;
            }
          }
        }
      }
    }

    @else if $side =="x" or $side =="y" {
      .m-#{$side}-#{$space} {
        @if $side =="x" {
          margin-left: #{$space}px !important;
          margin-right: #{$space}px !important;
        }

        @else {
          margin-top: #{$space}px !important;
          margin-bottom: #{$space}px !important;
        }
      }

      .p-#{$side}-#{$space} {
        @if $side =="x" {
          padding-left: #{$space}px !important;
          padding-right: #{$space}px !important;
        }

        @else {
          padding-top: #{$space}px !important;
          padding-bottom: #{$space}px !important;
        }
      }

      @each $breakpoint, $breakpoint-value in $breakpoints {
        @if $breakpoint-value !=0 {
          @media (max-width: $breakpoint-value) {

            div.#{$breakpoint}-m-#{$side}-#{$space},
            p.#{$breakpoint}-m-#{$side}-#{$space},
            button.#{$breakpoint}-m-#{$side}-#{$space},
            span.#{$breakpoint}-m-#{$side}-#{$space} {
              @if $side =="x" {
                margin-left: #{$space}px !important;
                margin-right: #{$space}px !important;
              }

              @else {
                margin-top: #{$space}px !important;
                margin-bottom: #{$space}px !important;
              }
            }

            div.#{$breakpoint}-p-#{$side}-#{$space},
            p.#{$breakpoint}-p-#{$side}-#{$space},
            button.#{$breakpoint}-p-#{$side}-#{$space},
            span.#{$breakpoint}-p-#{$side}-#{$space} {
              @if $side =="x" {
                padding-left: #{$space}px !important;
                padding-right: #{$space}px !important;
              }

              @else {
                padding-top: #{$space}px !important;
                padding-bottom: #{$space}px !important;
              }
            }
          }
        }
      }
    }

    @else {
      .m-#{str-slice($side, 0, 1)}-#{$space} {
        @if $side =="top" or $side =="bottom" {
          margin-#{$side}: #{$space}px !important;
        }

        @else if $side =="left" or $side =="right" {
          margin-#{$side}: #{$space}px !important;
        }
      }

      .p-#{str-slice($side, 0, 1)}-#{$space} {
        @if $side =="top" or $side =="bottom" {
          padding-#{$side}: #{$space}px !important;
        }

        @else if $side =="left" or $side =="right" {
          padding-#{$side}: #{$space}px !important;
        }
      }

      @each $breakpoint, $breakpoint-value in $breakpoints {
        @if $breakpoint-value !=0 {
          @media (max-width: $breakpoint-value) {

            div.#{$breakpoint}-m-#{str-slice($side, 0, 1)}-#{$space},
            p.#{$breakpoint}-m-#{str-slice($side, 0, 1)}-#{$space},
            button.#{$breakpoint}-m-#{str-slice($side, 0, 1)}-#{$space},
            span.#{$breakpoint}-m-#{str-slice($side, 0, 1)}-#{$space} {
              @if $side =="top" or $side =="bottom" {
                margin-#{$side}: #{$space}px !important;
              }

              @else if $side =="left" or $side =="right" {
                margin-#{$side}: #{$space}px !important;
              }
            }

            div.#{$breakpoint}-p-#{str-slice($side, 0, 1)}-#{$space},
            p.#{$breakpoint}-p-#{str-slice($side, 0, 1)}-#{$space},
            button.#{$breakpoint}-p-#{str-slice($side, 0, 1)}-#{$space},
            span.#{$breakpoint}-p-#{str-slice($side, 0, 1)}-#{$space} {
              @if $side =="top" or $side =="bottom" {
                padding-#{$side}: #{$space}px !important;
              }

              @else if $side =="left" or $side =="right" {
                padding-#{$side}: #{$space}px !important;
              }
            }
          }
        }
      }
    }
  }
}

// COLORS classes genration
/* example c-white (color: white), bgc-white (background-color: white) */
@each $name, $color in $colors {
  .c-#{$name} {
    color: #{$color} !important;
  }

  .bgc-#{$name} {
    background-color: #{$color} !important;
  }
}

// notifications

:root {
  --toastify-toast-min-height: 48px;
  --toastify-font-family: var(--font-family-poppins);
}

.Toastify__toast-container {
  width: fit-content;
}

.Toastify__toast {
  width: fit-content;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0;
  padding-left: 12px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;

  .Toastify__toast-title {
    padding: 10px 0;
  }

  &.Toastify__toast--info {
    color: var(--color-gray-950);
    background: var(--color-white);

    .Toastify__close-icon {
      color: var(--color-gray-950);
    }
  }

  &.Toastify__toast--error {
    color: var(--color-white);
    background: var(--color-error);
  }

  &.Toastify__toast--success {
    color: var(--color-white);
    background: var(--color-success);
  }

  &.Toastify__toast--warning {
    color: var(--color-white);
    background: #f1c40f;
  }

  .Toastify__close-icon {
    color: var(--color-white);
    margin: 0 12px 0 auto;
    padding-left: 12px;
    width: 23px;
  }
}

.bitmovinplayer-container {
  max-height: 600px;
}

.bitmovinplayer-container {
  .bmpui-ui-playbacktogglebutton {
    position: absolute;
    left: 64px;
    bottom: 78px;
  }

  .bmpui-ui-volumecontrolbutton {
    position: absolute;
    left: 98px;
    bottom: 78px;
  }

  .bmpui-ui-volumetogglebutton {
    position: absolute;
    left: 98px;
    bottom: 78px;
  }

  .bmpui-ui-playbacktimelabel {
    font-family: var(--font-family-poppins);
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    left: 64px;
    bottom: 24px;
  }

  .bmpui-ui-fullscreentogglebutton {
    position: absolute;
    bottom: 78px;
    right: 64px;
  }

  .bmpui-ui-settingstogglebutton {
    position: absolute;
    bottom: 78px;
    right: 98px;
  }

  .bmpui-ui-settings-panel {
    bottom: 120px;
  }

  // .bmpui-ui-controlbar {
  //   // height: 100%;
  // }

  .bmpui-ui-seekbar {
    position: absolute;
    bottom: 54px;
    width: calc(100% - 128px);
    left: 64px;
  }

  .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker,
  .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
    background-color: var(--color-white);
    border-color: var(--color-white);
  }

  .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition,
  .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
    background-color: var(--color-white);
  }

  .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel,
  .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel {
    background-color: var(--color-gray-400)
  }

  .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop,
  .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
    background-color: var(--color-gray-600);
  }

  .bmpui-liveLabel {
    position: absolute;
    bottom: 78px;
    left: 144px;
  }

  .bmpui-ui-playbacktimelabel-live {
    position: absolute;
    bottom: 83px;
    left: 144px;
  }

  .bmpui-supportCreator {
    position: absolute;
    top: 32px;
    right: 64px;
  }

  .bmpui-infoElement {
    position: absolute;
    top: 32px;
    left: 64px;
  }
}