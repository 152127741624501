.playerPlaceholderWrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 600px;
  overflow: hidden;
  background: #000;

  &::before {
    content: "";
    display: block;
    padding-top: 56.25%; // 16:9 aspect ratio (height / width * 100)
  }

  .playerContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}