.ourMission {
  background-color: var(--color-primary-500);
  padding: 80px 0;
  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 170px;
  }
  &Text {
    span {
      font-family: var(--font-family-spacemono);
      font-style: italic;
      font-size: 41px;
      line-height: 57px;
      letter-spacing: -0.02em;
      font-weight: 700;
    }
  }
}

@media (max-width: 772px) {
  .ourMission {
    padding: 40px 0;
    &Row {
      flex-direction: column;
      gap: 40px;
    }
  }
}
