.info {
  &Title {
    border-bottom: 1px solid var(--color-gray-900);
    &Typography {
      display: inline-block;
      position: relative;
      padding-bottom: 16px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
        position: absolute;
        bottom: -1px;
      }
    }
  }
  &Description {
    color: var(--color-gray-300);
    font-family: var(--font-family-poppins);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    > p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  &Row {
    display: grid;
    grid-template-columns: 3fr repeat(2, 1fr);
    grid-column-gap: 128px;

    @media (max-width: 700px) {
      display: block;
    }
  }
  &Details {
    padding: 64px 0 96px;
  }
}
