// Typography mixin
@mixin typography-style($font-family, $font-size, $line-height) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin typography-color($color, $type) {
  color: var(--color-#{$type}-#{$color});
}

.typography {

  // Heading styles
  &.h1 {
    @include typography-style(var(--font-family-kanit), 62px, 74px);
  }

  &.h2 {
    @include typography-style(var(--font-family-kanit), 56px, 67px);
  }

  &.h3 {
    @include typography-style(var(--font-family-kanit), 48px, 57px);
  }

  &.h4 {
    @include typography-style(var(--font-family-kanit), 32px, 40px);
  }

  &.h5 {
    @include typography-style(var(--font-family-kanit), 28px, 34px);
  }

  &.h6 {
    @include typography-style(var(--font-family-kanit), 24px, 30px);
  }

  &.h7 {
    @include typography-style(var(--font-family-kanit), 20px, 28px);
  }

  // Body styles
  &.bodyXl {
    @include typography-style(var(--font-family-poppins), 20px, 30px);
  }

  &.bodyL {
    @include typography-style(var(--font-family-poppins), 18px, 28px);
  }

  &.bodyMd {
    @include typography-style(var(--font-family-poppins), 16px, 24px);
  }

  &.bodyS {
    @include typography-style(var(--font-family-poppins), 14px, 22px);
  }

  &.bodyXs {
    @include typography-style(var(--font-family-poppins), 12px, 18px);
  }

  // Color styles
  @each $color in (25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 850, 900, 950) {
    &.gray#{$color} {
      @include typography-color($color, "gray");
    }

    &.primary#{$color} {
      @include typography-color($color, "primary");
    }

  }

  &.white {
    color: var(--color-white);
  }

  &.black {
    color: var(--color-black);
  }

  &.error {
    color: var(--color-error);
  }

  &.noWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Alignment styles
  @each $align in (center, left, right, justify, inherit) {
    &.align-#{$align} {
      text-align: $align;
    }
  }

  // Weight styles
  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }

  &.semibold {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }
}