.button {
  font-size: 16px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: var(--color-gray-950);
  font-weight: 500;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding: 0;

  &.primary,
  &.secondary {
    border-radius: 48px;
    padding: 8px 24px;
  }

  &.primary {
    background-color: var(--color-primary-500);
    border: 1px solid var(--color-primary-300);

    &:hover {
      background-color: var(--color-primary-500);
      border-color: var(--color-primary-700);
      box-shadow:
        inset 0px 0px 8px 0px #34333740,
        0px 6px 10px 0px #ebff2233;
    }

    &.disabled {
      background-color: var(--color-primary-800);
      border-color: var(--color-primary-800);
      pointer-events: none;
    }
  }

  &.secondary {
    color: var(--color-white);
    background-color: var(--color-gray-900);
    border: 1px solid var(--color-gray-800);
    backdrop-filter: blur(40px);

    &:hover {
      background-color: var(--color-gray-800);
      border-color: var(--color-gray-700);
    }

    &.disabled {
      background-color: var(--color-gray-900);
      border-color: var(--color-gray-900);
      pointer-events: none;
    }
  }

  &.tertiary {
    color: var(--color-white);

    &:hover {
      background-color: var(--color-gray-900);
    }

    &.disabled {
      color: var(--color-gray-700);
      pointer-events: none;
    }
  }

  &.link {
    color: var(--color-white);
    position: relative;
    height: unset !important;
    padding: 0 !important;

    &::after {
      content: "";
      height: 1px;
      background-color: var(--color-white);
      width: 0;
      transition: all 0.3s;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &.disabled {
      color: var(--color-gray-700);
      pointer-events: none;
    }
  }

  &.loading {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.sizeL {
    font-size: 20px;
    line-height: 28px;
    height: 64px;
    // padding: 17px 32px;
    padding: 0 32px;

    &.circle {
      width: 64px;
    }
  }

  &.sizeM {
    height: 48px;
    font-size: 16px;
    line-height: 26px;
    // padding: 12px 24px;
    padding: 0 24px;

    &.circle {
      width: 48px;
    }
  }

  &.sizeS {
    height: 40px;
    font-size: 16px;
    line-height: 26px;
    // padding: 8px 16px;
    padding: 0 16px;

    &.circle {
      width: 40px;
    }
  }

  &.sizeXS {
    height: 32px;
    font-size: 14px;
    line-height: 22px;
    // padding: 5px 16px;
    padding: 0 16px;

    &.circle {
      width: 32px;
    }
  }

  &.circle {
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.nav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
