.header {
  padding-top: calc(128px + 96px);
  padding-bottom: 140px;
  background-image: url("../../../../assets/images/landing-bg.webp");
  background-size: cover;
  background-position: 145px;
  position: relative;
  &::before {
    content: "";
    background: #100f14bf;
    position: absolute;
    inset: 0;
  }
  &Content {
    position: relative;
    z-index: 10;
  }
  &Figure {
    position: absolute;
    top: calc(111px + 96px);
    left: 460px;
    pointer-events: none;
  }
  &Title {
    text-transform: uppercase;
    &.spaceMono {
      font-family: var(--font-family-spacemono);
      font-style: italic;
      line-height: 62px;
    }
  }
}

@media (max-width: 772px) {
  .header {
    padding-top: 160px;
    padding-bottom: 64px;
    &Figure {
      display: none;
    }
    .headerTitle {
      font-size: 44px;
      line-height: 44px;
    }
  }
}
