.header {
  padding: 64px 0;

  &Date {
    display: flex;
    align-items: center;
    gap: 12px;

    &Divider {
      display: block;
      width: 3px;
      height: 3px;
      background-color: var(--color-gray-200);
      border-radius: 50%;
    }
  }
}

.watchBlock {
  display: flex;
  align-items: center;

  .showIcon {
    margin-right: 8px;
    color: var(--color-gray-300);
  }
}