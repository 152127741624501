.tooltipWrapper {
  position: relative;
}

.tooltipTrigger {
  width: 100%;
}

.tooltipContent {
  width: max-content;
  max-width: 342px;
  color: var(--color-white);
  background: var(--color-black);
  border-radius: 8px;
  padding: 16px;
  z-index: 1000;

  &[data-popper-placement^="top"] .tooltipArrow {
    // bottom: -5px;
    // left: 50%;
    // background: red;
    // transform: translateX(-50%) rotate(45deg);
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px 5px 0 5px;
    border-color: var(--color-black) transparent transparent transparent;

  }

  &[data-popper-placement^="bottom"] .tooltipArrow {
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent var(--color-black) transparent;
  }

  &[data-popper-placement^="left"] .tooltipArrow {
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent var(--color-black);
  }

  &[data-popper-placement^="right"] .tooltipArrow {
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px 5px 5px 0;
    border-color: transparent var(--color-black) transparent transparent;
  }
}

.tooltipArrow {
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  border-style: solid;
}