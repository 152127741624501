.checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    // .checkboxIcon {
    //   border: 1px solid var(--color-primary-500);
    // }
  }

  // &.checked {}

  &.disabled {
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    display: none;
  }

  .checkboxIcon {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-gray-600);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
      color: var(--color-primary-500);
      width: 14px;
      height: 14px;
    }

    &:hover {
      border: 1px solid var(--color-primary-500);
    }

    &.checked {
      svg {
        display: block;
      }

      &:hover {
        background: var(--color-primary-900);
      }

      border: 1px solid var(--color-primary-500);
    }
  }

  .checkboxLabel {
    flex: 1;
    text-align: left;
  }
}