.qrReader {
  width: 430px;
  height: 100vh;
  margin: 0 auto;
  position: relative;

  &Video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &Box {
    width: 100% !important;
    left: 0 !important;
  }

  &Frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

@media (max-width: 426px) {
  .qrReader {
    width: 100%;
  }
}
