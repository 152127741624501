.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 99;
  display: none;

  &.visible {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal {
    border-radius: 8px;
    border: 1px solid var(--color-gray-800);
    position: fixed;
    background: var(--color-background);
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 95vh;

    .closeIcon {
      color: var(--color-gray-600);
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 18px;
      height: 14px;
      width: 14px;

      &:focus {
        outline: none;
      }

      &:focus-visible,
      :global(&.focus-visible) {
        outline: revert;
      }

      &:hover {
        color: var(--color-white);
      }
    }

    .modalTitle {
      font-family: var(--font-family-kanit);
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      padding: 48px 48px 12px 48px;
    }

    .modalMain {
      padding: 0 48px 32px 48px;
      flex: 1;
      overflow: auto;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-gray-300);
    }

    .modalFooter {
      padding: 16px 48px 48px 48px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .footerButton {
        margin-left: 8px !important;

        &.loading svg {
          width: min-content !important;
          ;
        }
      }
    }
  }
}