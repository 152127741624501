.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-width: 100%;
  width: 600px;
  background-color: var(--color-background);
  border-radius: 12px 12px 0 0;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &CloseIcon {
    cursor: pointer;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-bottom: 12px;

  .button {
    color: var(--color-white);
    background-color: var(--color-error);
    border: 2px solid var(--color-background);

    &:hover {
      background-color: var(--color-error);
      border: 2px solid var(--color-background);
      opacity: 0.7;
      box-shadow: none;
    }
  }
}
