.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }
}

.paymentButtons {
  display: flex;
  align-items: center;
  padding-top: 32px;
  gap: 16px;
  justify-content: center;
}