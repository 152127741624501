.wrap {
  position: relative;
}

.avatar {
  background-color: var(--color-gray-100);
  color: var(--color-gray-900);
  font-weight: 500;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}