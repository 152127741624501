$grid-columns: 12;
$default-spacing: 32px;
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);
// Access the 'sm' breakpoint value
$breakpoint-sm: map-get($breakpoints, sm);
$breakpoint-lg: map-get($breakpoints, lg);
$spacing-options: (
  "sm": 8px,
  "md": 16px,
  "lg": 32px
);

:root {
  --color-white: #ffffff;
  --color-black: #1b1b24;
  --color-background: #100f14;

  --color-primary-25: #fdffe9;
  --color-primary-50: #faffc8;
  --color-primary-100: #f7ffa7;
  --color-primary-200: #f4ff85;
  --color-primary-300: #f1ff64;
  --color-primary-400: #eeff43;
  --color-primary-500: #ebff22;
  --color-primary-600: #c1d11c;
  --color-primary-700: #96a316;
  --color-primary-800: #6c7510;
  --color-primary-850: #42470a;
  --color-primary-900: #181a03;

  --color-gray-25: #f3f4f6;
  --color-gray-50: #e8e8ed;
  --color-gray-100: #dcdde4;
  --color-gray-200: #d0d2db;
  --color-gray-300: #b9bbc9;
  --color-gray-400: #a1a5b7;
  --color-gray-500: #8a8ea5;
  --color-gray-600: #6f7285;
  --color-gray-700: #545665;
  --color-gray-800: #393b44;
  --color-gray-850: #2b2c33;
  --color-gray-900: #1e1f24;
  --color-gray-950: #111114;

  --color-success: #42a148;
  --color-error: #dc3220;
  --color-info: #0c7bdc;

  --font-family-kanit: "Kanit", sans-serif;
  --font-family-poppins: "Poppins", sans-serif;
  --font-family-spacemono: "Space Mono", monospace;
}

$colors: (
  "white": var(--color-white),
  "black": var(--color-black),
  "background": var(--color-background),
  "primary-25": var(--color-primary-25),
  "primary-50": var(--color-primary-50),
  "primary-100": var(--color-primary-100),
  "primary-200": var(--color-primary-200),
  "primary-300": var(--color-primary-300),
  "primary-400": var(--color-primary-400),
  "primary-500": var(--color-primary-500),
  "primary-600": var(--color-primary-600),
  "primary-700": var(--color-primary-700),
  "primary-800": var(--color-primary-800),
  "primary-850": var(--color-primary-850),
  "primary-900": var(--color-primary-900),
  "gray-25": var(--color-gray-25),
  "gray-50": var(--color-gray-50),
  "gray-100": var(--color-gray-100),
  "gray-200": var(--color-gray-200),
  "gray-300": var(--color-gray-300),
  "gray-400": var(--color-gray-400),
  "gray-500": var(--color-gray-500),
  "gray-600": var(--color-gray-600),
  "gray-700": var(--color-gray-700),
  "gray-800": var(--color-gray-800),
  "gray-850": var(--color-gray-850),
  "gray-900": var(--color-gray-900),
  "gray-950": var(--color-gray-950),
  "success": var(--color-success),
  "error": var(--color-error),
  "info": var(--color-info)
);
