.vefify {
  flex: 1;
  &Content {
    width: 100%;
    max-width: 360px;
    margin: 96px auto 0;
    strong {
      color: var(--color-gray-25);
    }
  }
}
