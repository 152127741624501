.header {
  border-bottom: 1px solid var(--color-gray-900);
  background-color: transparent;
  transition: all 0.3s;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  &.background {
    background-color: var(--color-background);
  }
  &.hide {
    transform: translateY(-100%);
  }
  &.landing {
    position: fixed;
    border-bottom: none;
    .headerRow {
      height: 96px;
    }
  }
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
  }
}
