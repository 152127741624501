.purchase {
  width: 100%;
  max-width: 632px;
  margin: 0 auto;

  &Total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
  }

  &Button {
    text-align: center;
  }
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}