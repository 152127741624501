.quantitySelector {
  display: inline-flex;
  justify-content: space-between;
  gap: 17px;
  background-color: var(--color-black);
  border: 1px solid var(--color-gray-900);
  border-radius: 48px;
  overflow: hidden;
  padding: 10px 16px;
  &Button {
    background-color: transparent;
    border: none;
    padding: 0;
    transition: color 0.3s;
    color: var(--color-white);
    font-size: 0;
    &:disabled {
      color: var(--color-gray-700);
    }
  }
  &Count {
    width: 24px;
    text-align: center;
  }
}
