.header {
  &Row {
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  padding-top: 32px;
  padding-bottom: 48px;
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  .leftPart {
    width: 50%;
    display: flex;
    justify-content: center;

    .infoWrapper {
      width: 408px;
      padding: 0 24px;
      max-width: 100%;
    }
  }

  .rightPart {
    width: 60%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 152px;
  }

  .aboutInfoWrapper {
    width: 428px;
    padding: 0 24px;
    max-width: 100%;
  }

  .backIcon {
    position: absolute;
    top: 40px;
    left: 28px;
    cursor: pointer;
  }

  @media (max-width: 772px) {
    .leftPart {
      width: 100%;
      padding-left: 48px;
      padding-right: 48px;
    }

    .rightPart {
      display: none;
    }
  }
}

.welcomeModalBody {
  margin-top: 20px;
  display: flex;

  &Icon {
    color: var(--color-primary-500);
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  &Text {
    flex: 1;
  }
}