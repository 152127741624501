.carouselItem {
  margin: 0 24px;
  position: relative;
  border-radius: 6px;

  &.active {
    .carouselItemBlur {
      opacity: 0.2;
    }

    .carouselItemContent,
    .carouselItemType {
      opacity: 1;
      visibility: visible;
    }
  }

  &Type {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 90;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;

    &Item {
      backdrop-filter: blur(30px);
      background: #38394533;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.live {
        width: 67px;
        background-color: #fe250e;
        border-radius: 40px;
        justify-content: space-between;
        padding: 0 8px;
      }
    }
  }

  &Blur {
    position: absolute;
    inset: 0;
    top: -40px;
    left: -48px;
    width: 854px;
    height: 393px;
    background-size: cover;
    border-radius: 20px;
    filter: blur(45px);
    opacity: 0;
    transition: opacity 0.3s;

    img {
      object-fit: cover;
      aspect-ratio: 16 / 9;
      height: 100%;
    }
  }

  &Image {
    &::before {
      content: "";
      background: linear-gradient(89.68deg, rgba(27, 27, 35, 0.3) 14.99%, rgba(27, 27, 35, 0) 78.02%),
        linear-gradient(179.77deg, rgba(27, 27, 35, 0) 51.26%, #1b1b23 99.68%),
        linear-gradient(0deg, rgba(27, 27, 35, 0.2), rgba(27, 27, 35, 0.2));
      position: absolute;
      inset: 0;
      z-index: 1;
      border-radius: 6px;
      transition: all 0.3s;
    }

    &.prev {
      &::before {
        background: linear-gradient(89.68deg, rgba(27, 27, 35, 0) 60.39%, rgba(16, 15, 20, 0.91) 91.18%),
          linear-gradient(179.77deg, rgba(27, 27, 35, 0) 51.32%, #1b1b23 99.8%),
          linear-gradient(0deg, rgba(13, 13, 13, 0.6), rgba(13, 13, 13, 0.6));
      }
    }

    &.next {
      &::before {
        background: linear-gradient(89.68deg, rgba(16, 15, 20, 0.91) 8.82%, rgba(27, 27, 35, 0) 39.62%),
          linear-gradient(179.77deg, rgba(27, 27, 35, 0) 51.32%, #1b1b23 99.8%),
          linear-gradient(0deg, rgba(13, 13, 13, 0.6), rgba(13, 13, 13, 0.6));
      }
    }

    &.disabled {
      pointer-events: none;
    }

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  &Date {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    background: #ebff2221;
    box-shadow: 0px 0px 14px 0px #ebff221a;
    padding: 8px 20px;
    border-radius: 36px;
    margin-bottom: 8px;
  }

  &Content {
    position: absolute;
    top: calc(427px - 137px);
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
}

.buttonsList {
  >button:nth-child(2) {
    margin-left: 8px;
  }
}