.header {
  padding: 32px 0 90px;
  position: relative;

  &Blur {
    position: absolute;
    inset: 0;
    top: -48px;
    left: 40px;
    width: 676px;
    height: 473px;
    background-size: cover;
    border-radius: 20px;
    filter: blur(40px);
    opacity: 0.2;

    img {
      object-fit: cover;
      aspect-ratio: 16 / 9;
      height: 100%;
    }
  }

  &Buy {
    &Row {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;
    }

    &Button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      &.secondary {
        span {
          background-color: var(--color-white);
        }
      }

      span {
        display: block;
        width: 3px;
        height: 3px;
        background-color: var(--color-gray-950);
        border-radius: 50%;
      }
    }
  }

  &Container {
    display: grid;
    grid-template-columns: 628px 1fr;
    gap: 100px;
    position: relative;

    @media (max-width: 700px) {
      display: block;
    }
  }

  &EventTitle {
    @media (max-width: 700px) {
      padding-top: 70px;
    }
  }

  &Image {
    border-radius: 20px;
    position: relative;
    height: 377px;

    &::before {
      content: "";
      background: linear-gradient(89.68deg, rgba(27, 27, 35, 0.3) 14.99%, rgba(27, 27, 35, 0) 78.02%),
        linear-gradient(180.07deg, rgba(27, 27, 35, 0) 60.3%, #1b1b23 95.14%),
        linear-gradient(0deg, rgba(27, 27, 35, 0.2), rgba(27, 27, 35, 0.2));
      border-radius: 20px;
      position: absolute;
      inset: 0;
      width: 628px;
      height: 100%;
    }

    img {
      border-radius: 21px;
      object-fit: cover;
      aspect-ratio: 16 / 9;
      height: 100%;
    }
  }

  &Date {
    display: flex;
    align-items: center;
    gap: 12px;

    &Divider {
      display: block;
      width: 3px;
      height: 3px;
      background-color: var(--color-gray-200);
      border-radius: 50%;
    }
  }
}

.allocationBudge {
  position: absolute;
  top: -7px;
  right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--color-error);
  border: 2px solid var(--color-background);
}

.actionRequired {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600px;
  background: var(--color-error);
  color: var(--color-white);
  border-radius: 12px;
  padding: 2px 16px;
  display: inline-block;
  margin-bottom: 8px;
}