.emailInputHelpIconTooltip {
  height: 16px;
  width: 16px;
}

.emailInputHelpIcon {
  height: 16px;
  width: 16px;
  color: var(--color-gray-400);
  cursor: pointer;

  &:hover {
    color: var(--color-gray-300);
  }
}

.socialButton {
  svg {
    height: 20px;
  }
}