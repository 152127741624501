.purchaseCompleted {
  padding: 48px 0;
  position: relative;

  &::before {
    content: "";
    background-image: url("../../assets/images/c68cd7611ee165bc9981fba9a45ed5fb.webp");
    background-size: cover;
    opacity: 0.26;
    width: 790px;
    height: 395px;
    position: absolute;
    left: 0;
    bottom: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
  }

  &::after {
    content: "";
    background-image: url("../../assets/images/2c510ef9a9eb02c075f25679a63d5f5a.webp");
    background-size: cover;
    opacity: 0.08;
    width: 910px;
    height: 607px;
    transform: rotate(-180deg);
    position: absolute;
    right: 0;
    top: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
  }

  &Header {
    text-align: center;
    margin-bottom: 48px;
  }

  &Footer {
    text-align: center;

    &Btn {
      margin-left: 16px;

      @media (max-width: 700px) {
        margin: 10px 0;
        margin-left: 16px;
        width: 239px;
      }
    }
  }
}

.ticket {
  padding: 48px 288px 74px;
  display: flex;
  margin: 0 auto;
  gap: 30px;

  @media (max-width: 700px) {
    display: block;
  }

  &Image {
    position: relative;
    width: 264px;
    flex: 0 0 264px;

    img {
      width: 264px;
      height: 264px;
      object-fit: cover;
      aspect-ratio: 16 / 9;
      transform: rotate(-6deg) translateY(1.8px);
      transform-origin: right;
    }

    &::after {
      content: "";
      background-image: url("../../assets/images/wave.svg");
      background-size: 8px 18px;
      position: absolute;
      width: 8px;
      height: 100%;
      transform: rotate(-6deg) scale(-1);
      transform-origin: right;
      right: 6px;
      z-index: 10;
      background-repeat: space;
      top: 2px;
      backface-visibility: hidden;
    }
  }

  &Content {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-white);
    width: 100%;
    position: relative;

    &::before {
      content: "";
      background-image: url("../../assets/images/wave.svg");
      background-size: 8px 18px;
      position: absolute;
      width: 8px;
      height: 100%;
      transform-origin: right;
      left: -2px;
      z-index: 10;
      background-repeat: space;
      top: 0px;
      backface-visibility: hidden;
    }

    &Title {
      margin-bottom: 8px;
      height: 60px;
    }

    &Info {
      padding: 24px 24px 0;
      width: 100%;
    }

    &Location {
      font-family: var(--font-family-spacemono);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: var(--color-gray-800);
      margin-bottom: 24px;
    }

    &Date {
      font-family: var(--font-family-spacemono);
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      color: var(--color-gray-950);
      margin-bottom: 8px;
    }

    &Time {
      display: flex;
      justify-content: space-between;
      font-family: var(--font-family-spacemono);
      color: var(--color-gray-950);
      margin-bottom: 24px;
      max-width: 280px;
      width: 100%;

      &Value {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }

      &Key {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
      }

      &Item {
        display: flex;
        flex-direction: column;
      }
    }

    &Footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var(--font-family-spacemono);
      background-color: var(--color-gray-50);
      color: var(--color-gray-950);
      padding: 8px 12px;
      border-radius: 8px 8px 0 0;
      font-size: 12px;

      &Price {
        font-weight: 700;
        line-height: 18px;
      }

      &Btn {
        margin-left: 16px !important;

        @media (max-width: 700px) {
          margin: 10px 0 !important;
          width: 239px !important;
        }
      }
    }

    &Logo {
      transform: rotate(180deg) scale(-1);
      padding: 8px 8px 8px 0px;
      display: flex;
      align-items: center;
    }
  }
}

@media (max-width: 772px) {
  .ticket {
    padding-left: 16px;
    padding-right: 16px;
  }
}