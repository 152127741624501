.info {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &Image {
    object-fit: cover;
    aspect-ratio: 16 / 9;
    height: 100%;
    border-radius: 6px;
  }

  &Date {
    display: flex;
    align-items: center;
    gap: 12px;
    &Divider {
      display: block;
      width: 3px;
      height: 3px;
      background-color: var(--color-gray-200);
      border-radius: 50%;
    }
  }
}
