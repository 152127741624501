.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  &.prev {
    left: 40px;
  }
  &.next {
    right: 40px;
  }
}
