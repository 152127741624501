.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: rgba(16, 15, 20, 0.96);
  backdrop-filter: blur(15px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1001;
  overflow: hidden;

  &.left {
    border-right: 1px solid var(--color-gray-800);
    left: 0;
  }

  &.right {
    border-left: 1px solid var(--color-gray-800);
    right: 0;
  }

  &.top {
    border-bottom: 1px solid var(--color-gray-800);
    top: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  &.bottom {
    border-top: 1px solid var(--color-gray-800);
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }
}

.content {
  position: relative;
  z-index: 2;
  padding: 76px 52px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.closeIcon {
  position: absolute;
  top: 30px;
  width: 12px;
  height: 12px;
  right: 30px;
  cursor: pointer;
  color: var(--color-gray-300);
  z-index: 3;

  &:hover {
    color: var(--color-white);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: linear-gradient(180deg, rgba(27, 27, 35, 0.60) 0.06%, rgba(27, 27, 35, 0.00) 34.9%), linear-gradient(180deg, rgba(27, 27, 35, 0.00) 60.3%, rgba(27, 27, 35, 0.60) 95.14%);
  z-index: 99;
}