.selectWrapper {
  font-family: var(--font-family-poppins);

  .label {
    display: block;
  }

  .select {
    background: transparent;
    width: 100%;
    position: relative;
    border-radius: 4px;
    border: 1px solid var(--color-gray-700);
    padding: 3px 14px 3px 4px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.focus-visible,
    &:focus-visible {
      outline: 1px solid var(--color-primary-500);
      border: 1px solid var(--color-primary-500);
    }

    &:hover:not(.read-only):not(:focus):not(.disabled) {
      cursor: pointer;
      border: 1px solid var(--color-gray-600);
    }

    &:focus:not(.disabled) {
      border: 1px solid var(--color-primary-500)
    }

    &.invalid {
      border-color: var(--color-error)
    }

    &.disabled {
      background-color: var(--color-gray-850);
      cursor: not-allowed;
    }

    .selectIcon {
      color: var(--color-gray-200);
      margin-left: 8px;
      min-width: 20px;
      width: 24px;
      height: 24px;
    }

    .selectContent {
      width: calc(100% - 16px - 10px);
      min-height: 34px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .placeholder {
        margin-left: 10px;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-gray-700);
      }

      .value {
        margin-left: 10px;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-gray-200);
        display: flex;
        flex-wrap: nowrap;

        .option-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          width: 20px;
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .textWrapper {
          display: table;
          table-layout: fixed;
          width: 100%;

          &.withOptions {
            width: unset;
          }

          >div {
            display: table-cell;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .valueItem {
        margin-top: 1px;
        margin-bottom: 1px;
        height: 32px;
        font-size: 15px;
        color: var(--color-white);
        padding: 7px 7px 7px 10px;
        background: var(--color-gray-850);
        border-radius: 4px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        max-width: 100%;

        span {
          white-space: nowrap;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1;
        }

        svg {
          color: var(--color-gray-700);
          margin-left: 7px;
          width: 16px;
          height: 16px;

          &:hover {
            color: var(--color-gray-600);
          }
        }
      }
    }

    .right-icon {
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--color-gray-600);

      &.open {
        color: var(--color-success);
      }

      svg {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
    }
  }

  // &:not(.fullWidth) {
  //   .select {
  //     .selectContent {
  //       .value {
  //         .textWrapper {
  //           width: unset;
  //         }
  //       }
  //     }
  //   }
  // }
}

:global(#root) {
  .selectSource {
    left: -1px;
    border: 1px solid var(--color-gray-600);
    font-family: var(--font-family-poppins);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 2px 0;
    background: var(--color-background);
    overflow: auto;
    z-index: 101;

    .selectSearch {
      margin: 2px 5px;

      input {
        &::placeholder {
          color: var(--color-gray-500);
        }
      }

      &Icon {
        color: var(--color-gray-500);
      }
    }

    .option {
      cursor: pointer;
      display: flex;
      padding: 2px 5px;
      color: var(--color-white);
      font-size: 16px;
      line-height: 24px;

      .optionContent {
        width: 100%;
        padding: 10px 8px;
        border-radius: 6px;
      }


      &.selected {
        color: var(--color-primary-500);

        .label {
          .checkbox-icon {
            border: none;
            background: var(--color-gray-600);
            ;
            color: var(--color-white);

            svg {
              width: 10px;
              height: 7px;
            }
          }

        }
      }

      &:hover {
        .optionContent {
          background: var(--color-gray-850);
        }
      }

      &.highligted {
        border: 1px solid var(--color-gray-600);
      }

      .label {
        display: flex;
        align-items: center;

        .checkbox-icon {
          color: var(--color-white);
          background: var(--color-white);
          box-shadow: 0px 1px 1px rgba(var(--color-gray-800), 0.05);
          border-radius: 3px;
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin-right: 12px;
          border: 1px solid var(--color-gray-600);
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          width: fit-content;
        }

        .rightSelectedIcon {
          padding-left: 8px;
          color: var(--color-primary-500);

          svg {
            width: 13.333px;
            height: 9.167px;
          }

          margin-left: auto;

        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .emptyText {
      text-align: center;
      padding: 12px 12px;
    }
  }
}