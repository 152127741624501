.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-width: 100%;
  width: 600px;
  background-color: var(--color-background);
  border-radius: 12px 12px 0 0;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Item {
    display: flex;
    align-items: center;
    gap: 16px;

    .invalidCircleIcon {
      width: 24px;
      height: 24px;
      color: var(--color-error);
    }
  }

  &CloseIcon {
    cursor: pointer;
  }
}
