.purchaseItem {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 32px;
  &Name {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 24px;
  }
  &Image {
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 6px;
  }
  &Date {
    display: flex;
    align-items: center;
    gap: 12px;

    &Divider {
      display: block;
      width: 3px;
      height: 3px;
      background-color: var(--color-gray-200);
      border-radius: 50%;
    }
  }
}
