.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px;
  max-width: 100%;
  width: 600px;
}

.logo {
  height: 72px;
}
